/* global contactModule:true */

angular.module('com.aa.contact').service('FormErrorsService', ['$timeout', function ($timeout) {

    var responseHandler = {

        handleServerError: function () {
            focusOnErrorMessageHeader();
        },

        handleFormErrors: function (formID, errors) {
            handleAssistanceTypeNoneSelectedError(errors.fieldErrors);
            handlePhoneErrors(errors.fieldErrors);
            handleAcknowledgeUnchecked(errors.fieldErrors);
            contactModule.contactAA.populateFormErrorsFromJSON(formID, errors.fieldErrors);
            showErrorCount();
            focusOnErrorMessageHeader();
        },

        clearErrors: function (formID) {
            contactModule.contactAA.clearFormErrors(formID);
            contactModule.contactAA.errorSummary.hideLinks("#errorSummary");
            $j('#errorSummary').find('p.no-margin').empty();
        }
    };

    var handleAcknowledgeUnchecked = function (fieldErrors) {
        var acknowledgeError = fieldErrors['formAcknowledge'] || false;
        if(acknowledgeError) {
            $j('#formAcknowledgementErrors').append(fieldErrors['formAcknowledge']);
            $j('#formAcknowledgementErrors').addClass('message-inline-error');
        }
    };

    var handleAssistanceTypeNoneSelectedError = function (fieldErrors) {
        var hasAssistanceTypeNoneSelectedError = fieldErrors['assistanceTypeInfo.noneSelected'] || false;
        if (hasAssistanceTypeNoneSelectedError) {
            $j('#assistanceTypeNoneSelectedErrors').append(fieldErrors['assistanceTypeInfo.noneSelected']);
            $j('#assistanceTypeNoneSelectedErrors').addClass('message-inline-error');
        }
    };

    var handlePhoneErrors = function (fieldErrors) {
        var hasPrimaryPhoneError = fieldErrors['contactInfo.emailPhoneForm.phones[0]'] || false;
        if (hasPrimaryPhoneError) {
            $j('#primaryPhoneErrors').append(fieldErrors['contactInfo.emailPhoneForm.phones[0]']);
            $j('#primaryPhoneErrorsContainer').addClass('is-error');
        }

        var hasSecondaryPhoneError = fieldErrors['contactInfo.emailPhoneForm.phones[1]'] || false;
        if (hasSecondaryPhoneError) {
            $j('#secondaryPhoneErrors').append(fieldErrors['contactInfo.emailPhoneForm.phones[1]']);
            $j('#secondaryPhoneErrorsContainer').addClass('is-error');
        }
        var hasPrimaryPhoneError1 = fieldErrors['contactInfo.emailPhoneForm.primaryPhone'] || false;
        if (hasPrimaryPhoneError1) {
            $j('#primaryPhoneErrors').append(fieldErrors['contactInfo.emailPhoneForm.primaryPhone']);
            $j('#primaryPhoneErrorsContainer').addClass('is-error');
            $j('#primaryPhoneContainer').addClass('is-error');
            $j('#primaryPhoneNumberLabel').addClass('is-error');
            $j('label[for="contactInfo.emailPhoneForm.phones[0].countryCodeReadonly"]').addClass('is-error');
        }

        var hasSecondaryPhoneError1 = fieldErrors['contactInfo.emailPhoneForm.secondaryPhone'] || false;
        if (hasSecondaryPhoneError1) {
            $j('#secondaryPhoneErrors').append(fieldErrors['contactInfo.emailPhoneForm.secondaryPhone']);
            $j('#secondaryPhoneErrorsContainer').addClass('is-error');
            $j('#secondaryPhoneContainer').addClass('is-error');
            $j('label[for="contactInfo.emailPhoneForm.phones[1].countryCodeReadonly"]').addClass('is-error');
        }
    };

    var showErrorCount = function () {
        var fieldSelector = 'div[id$="Errors"]';
        var fieldErrorCount = $j(fieldSelector).closest('label.is-error').length;
        var compositeFieldCount = $j(".js-compositeField").closest('.is-error').length;
        var inlineErrorMessage = $j('p.message-inline-error').length;
        var errorCount = fieldErrorCount + compositeFieldCount + inlineErrorMessage;

        if (errorCount === 1) {
            $j('#errorSummary').find('p').append(contactModule.errorMessage408);
        } else {
            $j('#errorSummary').find('p').append(contactModule.errorMessage409.replace("{0}", errorCount));
        }
    };

    var focusOnErrorMessageHeader = function () {
        $timeout(function () {
            contactModule.contactAA.setFocusOn('.message-error:visible h2');
            contactModule.contactAA.stopSpinner();
        }, 0);
    };

    return responseHandler;
}]);
